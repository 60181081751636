"use client"
import { ButtonBase, ButtonBaseProps, Tooltip } from "@mui/material"
import { copyToClipboard } from "@tc/ui-shared/utils"
import React, { useState } from "react"
export interface CopyToClipboardProps {
  children: React.ReactNode
  text: string
  onCopy?: (
    text: string,
    success: boolean,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void
  options?: {
    placement?: "left" | "right" | "top" | "bottom"
    message?: string
    messageTimeout?: number
    buttonProps?: ButtonBaseProps
  }
}
export const CopyToClipboard = (props: CopyToClipboardProps) => {
  const { children, text, onCopy, options } = props
  const [copied, setCopied] = useState(false)
  const onClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const success = await copyToClipboard(text)
    onCopy?.(text, success, event)
    setCopied(success)
  }

  return (
    <Tooltip
      title={options?.message || "Copied!"}
      placement={options?.placement || "top"}
      onClose={() => setCopied(false)}
      open={copied}
    >
      <ButtonBase onClick={onClick} disableRipple disableTouchRipple>
        {children}
      </ButtonBase>
    </Tooltip>
  )
}
