"use client"
import Prism from "prismjs"
import "prismjs/themes/prism.css"
import "./index.css"
import { useEffect } from "react"

export interface CodeProps {
  code: string
  language: string
}

export const Code = (props: CodeProps) => {
  useEffect(() => {
    Prism.highlightAll()
  }, [])
  return (
    <div className="line-numbers">
      <pre>
        <code className={`language-${props.language}`}>{props.code}</code>
      </pre>
    </div>
  )
}

export default Code
