"use client"
import { Close } from "@mui/icons-material"
import {
  Autocomplete,
  Chip,
  ChipPropsColorOverrides,
  TextField,
  TextFieldProps,
} from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import Stack from "@mui/material/Stack"
import { MenuItemKeyValuePair } from "@tc/ui-shared/utils"
import { useId } from "react"
import { Controller, FieldValues, UseControllerProps } from "react-hook-form"

export type FormAutocompleteProps<T extends FieldValues> = TextFieldProps &
  UseControllerProps<T> & {
    options: readonly MenuItemKeyValuePair[]
    multiple?: boolean
    isStatus?: boolean
  }

export const FormAutocomplete = <T extends FieldValues>(
  props: FormAutocompleteProps<T>,
) => {
  const { id, name, control, options, isStatus, multiple, ...textFieldProps } =
    props
  const autoId = useId()
  const _id = id || autoId
  const fieldState = control?.getFieldState(name)
  return (
    <Stack>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Autocomplete
              id={_id}
              multiple={multiple}
              options={options}
              data-testid="test-formAutocomplete"
              defaultValue={[]}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              getOptionLabel={(option: MenuItemKeyValuePair) => {
                return option.label ?? ""
              }}
              onChange={(e, data) => {
                // always return an array
                const arrayData = Array.isArray(data)
                  ? data.map((a) => a.value)
                  : data?.value
                field.onChange(arrayData)
              }}
              renderOption={(props, option, { selected }) => {
                return (
                  <MenuItem {...props} key={option.value} selected={selected}>
                    {option.label}
                  </MenuItem>
                )
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    {...field}
                    error={fieldState?.invalid}
                    helperText={
                      fieldState?.error?.message as string | undefined
                    }
                    {...textFieldProps}
                    inputRef={field.ref}
                  />
                )
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                  const { key, ...rest } = getTagProps({ index })
                  return (
                    <Chip
                      {...rest}
                      color={
                        isStatus
                          ? (option.value as keyof ChipPropsColorOverrides)
                          : "primary"
                      }
                      key={option.value}
                      label={option.label}
                      size="small"
                      deleteIcon={<Close fontSize="small" />}
                    />
                  )
                })
              }
            />
          )
        }}
      ></Controller>
    </Stack>
  )
}
