import { TemplateVersionStatus } from "@tc/graphql-server"

const WHITE = "#FFFFFF"
const BLACK = "#000000"
const LIGHT_RED = "#FDEDED"
const AMBER = "#FEF7E5"
export type TemplateVersionStatusColor = Record<TemplateVersionStatus, string>
export const templateStatus: TemplateVersionStatusColor = {
  [TemplateVersionStatus.Approved]: "#4CAF50",
  [TemplateVersionStatus.Cancelled]: "#FF9800",
  [TemplateVersionStatus.Draft]: "#2196F3",
  [TemplateVersionStatus.Locked]: "#FF5722",
  [TemplateVersionStatus.Review]: "#9C27B0",
  [TemplateVersionStatus.Revoked]: "#795548",
  [TemplateVersionStatus.Suspended]: "#9E9E9E",
}

export const Color = {
  white: WHITE,
  black: BLACK,
  primary: {
    main: "#7749F8",
    text: "#212529",
  },
  secondary: {
    main: "#6C757D",
  },
  boarder: "#ADB5BD",
  background: {
    default: "#F5F5F5",
    paper: WHITE,
  },

  templateStatus,
  //TODO find a better way to handle alert color. MuiAlert styleOverrides icon warning issue
  alertError: {
    main: LIGHT_RED,
  },
  alertWarning: {
    main: AMBER,
  },
}

const colorOptions = {
  palette: {
    primary: {
      main: Color.primary.main,
    },
    secondary: {
      main: Color.secondary.main,
    },
    text: {
      primary: Color.primary.text,
      secondary: Color.secondary.main,
    },
    alertError: {
      main: Color.alertError.main,
    },
    alertWarning: {
      main: Color.alertWarning.main,
    },
    background: Color.background,
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderColor: Color.boarder,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: Color.background.paper,
        },
      },
    },
  },
}
export default colorOptions
