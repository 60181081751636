"use client"
import { Link as MuiLink, Stack } from "@mui/material"
import { LinkProps, default as NextLink } from "next/link"

export interface RouterLink extends LinkProps {
  href: URL | string
  children: JSX.Element | string
  external?: boolean
  custom?: boolean
  startIcon?: JSX.Element
  endIcon?: JSX.Element
}

export const Link = (props: RouterLink) => {
  const {
    href,
    children,
    external,
    custom,
    startIcon,
    endIcon,
    prefetch,
    ...others
  } = props
  const externalProps = external
    ? { target: "_blank", rel: "noopener noreferrer" }
    : {}
  if (custom) {
    return (
      <NextLink href={href} passHref legacyBehavior prefetch={prefetch}>
        {children}
      </NextLink>
    )
  }
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {startIcon}
      <MuiLink
        sx={{ width: "fit-content", cursor: "pointer" }}
        color="primary"
        variant="inherit"
        href={href}
        component={NextLink}
        prefetch={prefetch}
        {...externalProps}
        {...others}
      >
        {children}
      </MuiLink>
      {endIcon}
    </Stack>
  )
}

export default Link
