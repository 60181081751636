"use client"
import {
  Autocomplete,
  Chip,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material"
import Stack from "@mui/material/Stack"
import { useSearch } from "@tc/ui-shared/hooks"
import { MenuItemKeyValuePair, SearchParameters } from "@tc/ui-shared/utils"
import { debounce } from "lodash"
import { useEffect, useId, useState } from "react"
import {
  Controller,
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form"

export type FormSearchProps<T extends FieldValues> = TextFieldProps &
  UseControllerProps<T> & {
    collection: string
    multiple: boolean
    searchAsYouType: boolean
    typesenseApiKeyMutation: string
    options: MenuItemKeyValuePair[]
  }

export const FormSearch = <T extends FieldValues>(
  props: FormSearchProps<T>,
) => {
  const {
    id,
    name,
    control,
    collection,
    multiple,
    searchAsYouType,
    typesenseApiKeyMutation,
    rules,
    options,
    ...textFieldProps
  } = props
  const autoId = useId()
  const _id = id || autoId
  const fieldState = control?.getFieldState(name)
  const [value, setValue] = useState<MenuItemKeyValuePair[] | null>(null)
  const [inputValue, setInputValue] = useState("")
  const [searchOptions, setSearchOptions] =
    useState<MenuItemKeyValuePair[]>(options)
  const [open, setOpen] = useState(false)
  const { field } = useController({
    name,
    control,
  })
  const search = useSearch(typesenseApiKeyMutation)
  const searchData = debounce(
    async ({
      request,
      isOption,
    }: {
      request: {
        collectionName: string
        searchParameters: SearchParameters
      }
      isOption: boolean
    }) => {
      const searchResult = await search.searchData(request)
      if (!searchResult) return
      if (isOption) {
        setSearchOptions([...searchResult])
      } else {
        setValue(searchResult)
      }
    },
    50,
  )
  const searchById = () => {
    const searchParameters: SearchParameters = {
      q: field.value,
      query_by: "search_id",
      per_page: 1,
    }

    searchData({
      request: {
        collectionName: collection,
        searchParameters: searchParameters,
      },
      isOption: false,
    })
  }
  const searchByLabel = ({
    query,
    totalRecords,
  }: {
    query: string
    totalRecords: number
  }) => {
    const searchParameters: SearchParameters = {
      q: query,
      query_by: "label",
      per_page: totalRecords,
    }

    searchData({
      request: {
        collectionName: collection,
        searchParameters: searchParameters,
      },
      isOption: true,
    })
  }
  // First time load to populate the value if any
  useEffect(() => {
    if (search.keyFetching) return
    if (field.value && inputValue == "") {
      searchById()
      return
    }
  }, [search.keyFetching, inputValue, field.value])
  useEffect(() => {
    if (search.keyFetching) return
    if (value && value.length > 0 && value[0].label == inputValue) return
    if (!inputValue) return
    searchByLabel({ query: inputValue, totalRecords: 10 })
  }, [search.keyFetching, inputValue, value, field.value])

  useEffect(() => {
    if (open && options.length == 0) {
      searchByLabel({ query: "*", totalRecords: 30 })
    }
  }, [open])

  return (
    <Stack>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <Autocomplete
              id={_id}
              open={open}
              onOpen={() => {
                setOpen(true)
              }}
              onClose={() => {
                setOpen(false)
              }}
              data-testid="test-formTypesense"
              getOptionLabel={(
                option: MenuItemKeyValuePair | MenuItemKeyValuePair[],
              ) => {
                if (!option || (Array.isArray(option) && option.length === 0))
                  return ""
                return Array.isArray(option) ? option[0]?.label : option?.label
              }}
              isOptionEqualToValue={(
                option: MenuItemKeyValuePair | MenuItemKeyValuePair[],
                value: MenuItemKeyValuePair | MenuItemKeyValuePair[],
              ) => {
                if (
                  !value ||
                  !option ||
                  (Array.isArray(value) && value.length === 0) ||
                  (Array.isArray(option) && option.length === 0)
                )
                  return false

                const v = Array.isArray(value) ? value[0] : value

                return Array.isArray(option)
                  ? option[0].label === v.label
                  : option.label === v.label
              }}
              options={searchOptions || []}
              value={value}
              multiple={multiple}
              limitTags={2}
              noOptionsText="No data"
              onChange={(_, newValue) => {
                if (!newValue) {
                  setValue(null)
                  field.onChange(newValue)
                  return
                }

                //TODO sort correctly
                if (Array.isArray(newValue)) {
                  const newValues = newValue as MenuItemKeyValuePair[]
                  setSearchOptions(
                    newValues
                      ? [...new Set(newValues.concat(searchOptions))]
                      : searchOptions,
                  )
                  setValue(newValues)
                  // TODO put multiple values into array of strings in below onChange code
                  //field.onChange(newValue.value)
                } else {
                  setSearchOptions(
                    newValue
                      ? [...new Set([newValue].concat(searchOptions))]
                      : searchOptions,
                  )
                  setValue([newValue])
                  field.onChange(newValue.value)
                }
              }}
              onInputChange={(_, newInputValue) => {
                if (newInputValue == "") {
                  return
                }

                setInputValue(newInputValue)
              }}
              renderOption={(props, option, { selected }) => {
                const item: MenuItemKeyValuePair = Array.isArray(option)
                  ? option[0]
                  : option
                return (
                  <MenuItem {...props} key={item.value} selected={selected}>
                    {item.label}
                  </MenuItem>
                )
              }}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={Array.isArray(option) ? option[0].value : option.value}
                    label={
                      Array.isArray(option) ? option[0].label : option.label
                    }
                  />
                ))
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    {...field}
                    error={fieldState?.invalid}
                    helperText={
                      fieldState?.error?.message as string | undefined
                    }
                    {...textFieldProps}
                    inputRef={field.ref}
                  />
                )
              }}
            />
          )
        }}
      ></Controller>
    </Stack>
  )
}
