import { Theme, createTheme, responsiveFontSizes } from "@mui/material/styles"
import { deepmerge } from "@mui/utils"
import { TemplateVersionStatus } from "@tc/graphql-server"
import baseThemeOptions from "./base-options"
import type { TemplateVersionStatusColor } from "./color-options"
import colorOptions, { Color } from "./color-options"

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides extends TemplateVersionStatusColor {
    [TemplateVersionStatus.Approved]: true
    [TemplateVersionStatus.Cancelled]: true
    [TemplateVersionStatus.Draft]: true
    [TemplateVersionStatus.Locked]: true
    [TemplateVersionStatus.Review]: true
    [TemplateVersionStatus.Revoked]: true
    [TemplateVersionStatus.Suspended]: true
  }
}

const generateStatusColor = (theme: Theme) =>
  Object.values(TemplateVersionStatus).reduce((acc, key) => {
    const main = Color.templateStatus[
      key as keyof typeof Color.templateStatus
    ] as string
    return {
      ...acc,
      [key]: theme.palette.augmentColor({
        color: {
          main,
          contrastText: theme.palette.getContrastText(main),
        },
        name: key,
      }),
    }
  }, {})

const createCustomTheme = () => {
  const theme = createTheme(deepmerge(baseThemeOptions, colorOptions))
  return responsiveFontSizes(
    createTheme(theme, {
      palette: { ...generateStatusColor(theme) },
    }),
  )
}
const teoTheme = createCustomTheme()
export default teoTheme
