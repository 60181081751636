"use client"

import { CssBaseline } from "@mui/material"
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { ConfirmProvider } from "@tc/ui-shared/hooks"
import { SnackbarProvider } from "notistack"
import React from "react"
import { AlertSettings, nzLocaleText } from "./3rd-options"
import theme from "./createTheme"

export interface ThemeProviderProps {
  children?: React.ReactNode
}
export const ThemeProvider = (props: ThemeProviderProps) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="en-nz"
      localeText={nzLocaleText}
    >
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider {...AlertSettings}>
          <CssBaseline />
          <ConfirmProvider>{props.children}</ConfirmProvider>
        </SnackbarProvider>
      </MuiThemeProvider>
    </LocalizationProvider>
  )
}
