"use client"
import Stack from "@mui/material/Stack"
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers"
import { Dayjs } from "dayjs"
import { Controller, FieldValues, UseControllerProps } from "react-hook-form"

export type FormDatePickerProps<T extends FieldValues> =
  DatePickerProps<Dayjs> & UseControllerProps<T>

export const FormDatePicker = <T extends FieldValues>(
  props: FormDatePickerProps<T>,
) => {
  const { name, control, slotProps, rules, ...datePickerProps } = props
  const fieldState = control?.getFieldState(name)
  return (
    <Stack data-testid="test-formDatePicker">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <DatePicker
              {...field}
              slotProps={{
                textField: {
                  error: fieldState?.invalid,
                  helperText: fieldState?.error?.message as string | undefined,
                  ...slotProps?.textField,
                },
              }}
              {...datePickerProps}
              value={field.value === undefined ? null : field.value}
            />
          )
        }}
      ></Controller>
    </Stack>
  )
}
