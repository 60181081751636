"use client"
import { useAlert } from "@tc/ui-shared/hooks"
import { AuthUtilities, authExchange } from "@urql/exchange-auth"
import {
  CombinedError,
  UrqlProvider as UrqlClientProvider,
  cacheExchange,
  createClient,
  fetchExchange,
  mapExchange,
  ssrExchange,
} from "@urql/next"
import { SessionProvider, signOut, useSession } from "next-auth/react"
import { useRouter } from "next/navigation"
import React, { useMemo } from "react"

export interface AuthProviderProps {
  children: React.ReactNode
  APP_SYNC_URL: string
}

const getAuthToken = async () => {
  const session = await fetch("/api/auth/session")
    .then((res) => res.json())
    .catch(() => null)
  return session?.token?.idToken
}
const isAuthError = (error: CombinedError) => {
  const errorResponse = error.response as Response

  return (
    error.graphQLErrors.some((e) => e.extensions?.code === "FORBIDDEN") ||
    errorResponse.status === 401
  )
}
const cognitoExchange = authExchange(async (utilities: AuthUtilities) => {
  let token = ""
  return {
    addAuthToOperation: (operation) => {
      return utilities.appendHeaders(operation, {
        Authorization: token ? `Bearer ${token}` : "",
      })
    },
    willAuthError: () => !token,
    didAuthError: (error) => {
      return isAuthError(error)
    },
    refreshAuth: async () => {
      token = await getAuthToken()
    },
  }
})

export const AuthProvider = ({ children, APP_SYNC_URL }: AuthProviderProps) => {
  const router = useRouter()
  const alert = useAlert()
  const errorExchange = mapExchange({
    onError(error) {
      if (isAuthError(error)) {
        signOut()
      } else {
        alert.error(error.toString())
        //TODO log to AWS CloudWatch?
      }
    },
  })

  useSession({
    required: true,
    onUnauthenticated() {
      console.log("User is unauthenticated")
      router.push("/signin")
    },
  })

  const [urqlClient, ssr] = useMemo(() => {
    const ssr = ssrExchange()
    const client = createClient({
      url: APP_SYNC_URL,
      exchanges: [
        cacheExchange,
        ssr,
        errorExchange,
        cognitoExchange,
        fetchExchange,
      ],
      requestPolicy: "cache-and-network",
    })
    return [client, ssr]
  }, [])

  return (
    <UrqlClientProvider client={urqlClient} ssr={ssr}>
      {children}
    </UrqlClientProvider>
  )
}

export default SessionProvider
