"use client"
import { Close, Fullscreen, FullscreenExit } from "@mui/icons-material"
import {
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  IconButton,
  PaperProps,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { useEffect, useState } from "react"
import { Loading } from "../Loading"

export interface PopupProps extends DialogProps {
  children?: React.ReactNode
  header?: string
  actions?: React.ReactNode
  onSubmit?: (event: React.FormEvent) => void
  contentProps?: DialogContentProps
  loading?: boolean
  error?: boolean | React.ReactNode
  paperProps?: PaperProps
  onFullScreen?: (isFullScreen: boolean) => void
}

export const Popup = (props: PopupProps) => {
  const {
    children,
    header,
    open,
    actions,
    maxWidth = "md",
    fullWidth = true,
    onClose,
    onSubmit,
    contentProps,
    loading = false,
    error,
    paperProps,
    onFullScreen,
    ...rest
  } = props
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down("md"))
  const [fullScreen, setFullScreen] = useState(mdDown)
  const toggleFullWidth = (isFull?: boolean) => {
    const isFullScreen = isFull ?? !fullScreen
    setFullScreen(isFullScreen)
    onFullScreen?.(isFullScreen)
  }
  useEffect(() => {
    toggleFullWidth(mdDown)
  }, [mdDown])
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      PaperProps={{
        component: "form",
        onSubmit,
        sx: {
          width: "100%",
        },
        ...paperProps,
      }}
      {...rest}
    >
      <DialogTitle sx={{ p: 0 }}>
        <AppBar position="static" color="inherit" elevation={0}>
          <Toolbar variant="dense" disableGutters sx={{ px: 1 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ pl: 1, flexGrow: 1 }}
            >
              {header}
            </Typography>
            <Stack direction="row" spacing={1}>
              <IconButton
                onClick={() => toggleFullWidth()}
                size="small"
                data-testid="fullScreenBtn"
                sx={{
                  color: "secondary.light",
                }}
              >
                {fullScreen ? (
                  <FullscreenExit fontSize="small" />
                ) : (
                  <Fullscreen fontSize="small" />
                )}
              </IconButton>
              <IconButton
                onClick={(e) => onClose?.(e, "escapeKeyDown")}
                size="small"
                data-testid="closeBtn"
                sx={{
                  color: "secondary.light",
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            </Stack>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      {loading && (
        <DialogContent>
          <Loading />
        </DialogContent>
      )}
      {!loading && (
        <DialogContent dividers {...contentProps}>
          {children}
        </DialogContent>
      )}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}
