"use client"
import { Stack } from "@mui/material"
import Head from "next/head"
import React from "react"

export interface PageProps {
  children?: React.ReactNode
  title?: string
  sx?: object
}

export const Page = (props: PageProps) => {
  const { children, title = "MPI", sx } = props
  return (
    <Stack
      id="appPage"
      data-testid="appPage"
      sx={{
        backgroundColor: "background.default",
        flexGrow: 1,
        ...sx,
      }}
    >
      <Head>
        <title>{title}</title>
      </Head>
      {children}
    </Stack>
  )
}
