import { Maybe } from "@tc/graphql-server"

interface CollectionSetting {
  queryAttributes: string
  valueAttribute: string
  displayAttr: string
  placeholder: string
  customOption?: (item: object) => string
}

export const getCollectionDefaultSettings = (
  collectionName: string,
): Maybe<CollectionSetting> => {
  switch (collectionName) {
    case "countries":
      return {
        queryAttributes: "name,label,alpha_2_code,capital",
        valueAttribute: "alpha_2_code",
        displayAttr: "name",
        placeholder: "Select country",
      }
    case "commodity-types": {
      return {
        queryAttributes: "label,search_id",
        displayAttr: "label",
        valueAttribute: "search_id",
        placeholder: "Commodity",
      }
    }
    case "destination-markets": {
      return {
        queryAttributes: "label,search_id",
        displayAttr: "label",
        valueAttribute: "search_id",
        placeholder: "Destination Markets",
      }
    }
    default:
      return null
  }
}
